import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Element, Link as ScrollLink } from "react-scroll"
import { IconContext } from "react-icons"
import { FiChevronRight } from "react-icons/fi"
import styled, { css } from "styled-components"

import { media } from "../styles/media"

import { Layout } from "../components/Layout"
import PageHeaderMedia from "../components/PageHeaderMedia"
import SEO from "../components/SEO"
import { useLocationData } from "../hooks/locationHook"

const scrollAtt = {
  activeClass: "active",
  spy: true,
  smooth: true,
  offset: -70,
  duration: 500,
}

const IconRight = () => (
  <IconContext.Provider value={{ size: "1.32em", color: "#E50012" }}>
    <FiChevronRight />
  </IconContext.Provider>
)

const StyledLinks = styled.div`
  background: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 50px 10% 50px;
  a span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    svg {
      margin-left: 0.62em;
    }
  }
  ${() =>
    media.sp(css`
      padding: 20px 5% 20px;
    `)}
`

const StyledWrap = styled.div`
  padding: 50px 5% 100px;
  .safeSec {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0px 0 100px;
    .imgSec {
      width: 40%;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .contSec {
      width: 55%;
      h3 {
        font-weight: bold;
        font-size: 1.32em;
        margin-bottom: 15px;
      }
    }
  }
  .safeSec2 {
    align-items: center;
    position: relative;
    padding: 80px 0;
    .imgSec2 {
      width: 40%;
      .gatsby-image-wrapper {
        object-fit: contain;
      }
    }
    p {
      font-size: 0.9em;
      line-height: 1.32em;
    }
    .backBeige {
      display: block;
      content: "";
      width: 80%;
      height: 100%;
      position: absolute;
      top: 0;
      left: -5%;
      background: ${({ theme }) => theme.colors.light.brown};
      z-index: -1;
    }
  }
  ${() =>
    media.sp(css`
      .safeSec {
        margin-bottom: 50px;
        .imgSec {
          width: 30%;
        }
        .contSec {
          width: 65%;
        }
        &:nth-child(3) {
          .imgSec {
            width: 100%;
          }
          .contSec {
            width: 100%;
            margin-bottom: 0.62em;
          }
        }
      }
      .safeSec2 {
        padding: 30px;
        .imgSec2 {
          width: 100%;
        }
        .backBeige {
          width: 100%;
          left: 0;
        }
        .contSec {
          width: 100%;
          p {
            margin-bottom: 1.62em;
          }
        }
      }
    `)}
`

const CompanyActivities = ({ location }) => {
  const data = useStaticQuery(query)
  useLocationData(location.pathname)

  return (
    <Layout>
      <SEO>
        <title>活動・取り組み一覧｜フランソア</title>
        <meta
          name="description"
          content="		 		 		安全・安心への取り組み 			 			 				お客様に信頼して..."
        />
      </SEO>
      <PageHeaderMedia
        fluid={data.zoo.headerMedia.childImageSharp.fluid}
        pageTitles={data.zoo.headerTitle}
        pageText={data.zoo.headerSubtitle}
      />

      <StyledLinks>
        <ScrollLink {...scrollAtt} to="sec1">
          <span>
            安心・安全への取り組み
            <IconRight />
          </span>
        </ScrollLink>
        <ScrollLink {...scrollAtt} to="sec2">
          <span>
            地域との関わり
            <IconRight />
          </span>
        </ScrollLink>
        <a
          href="https://corporate.francois.co.jp/api/uploads/2024_disclosure_9ade78b9f1.pdf"
          target="_blank"
          rel="noreferrer noopener"
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: 18 }}>人事・労務に関する主なデータ</span>
          <IconRight />
        </a>
      </StyledLinks>

      <StyledWrap>
        <Element name="sec1" />
        <div className="safeSec">
          <div className="imgSec">
            <Image fluid={data.zoo.safeMedia.childImageSharp.fluid} />
          </div>
          <div className="contSec">
            <h3>{data.zoo.safeTitle}</h3>
            <p>{data.zoo.safeContents}</p>
          </div>
        </div>
        <div className="safeSec">
          <div className="contSec">
            <h3>{data.zoo.sagaTitle}</h3>
            <p>{data.zoo.sagaContents}</p>
          </div>
          <div className="imgSec">
            <Image fluid={data.zoo.sagaMedia.childImageSharp.fluid} />
          </div>
        </div>
        <div className="safeSec safeSec2">
          <div className="contSec">
            <h3>{data.zoo.fsscTitle}</h3>
            <p dangerouslySetInnerHTML={{ __html: data.zoo.fsscContents }} />
          </div>
          <div className="imgSec2">
            <Image fluid={data.zoo.fsscMedia.childImageSharp.fluid} />
          </div>
          <span className="backBeige"></span>
        </div>
        <div className="safeSec">
          <div className="imgSec">
            <Image fluid={data.zoo.areaMedia.childImageSharp.fluid} />
          </div>
          <Element name="sec2" />
          <div className="contSec">
            <h3>{data.zoo.areaTitle}</h3>
            <p dangerouslySetInnerHTML={{ __html: data.zoo.areaContents }} />
          </div>
        </div>
      </StyledWrap>
    </Layout>
  )
}

export const query = graphql`
  {
    zoo: strapiCompanyActivities {
      headerMediaAlt
      headerSubtitle
      headerTitle
      headerMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      safeTitle
      sagaContents
      safeMediaAlt
      safeMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sagaTitle
      safeContents
      sagaMediaAlt
      sagaMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fsscTitle
      fsscContents
      sffcMediaAlt
      fsscMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      areaTitle
      areaMediaAlt
      areaContents
      areaMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default CompanyActivities
